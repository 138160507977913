<template>
  <div class="exhibit"  v-touch:swipe.left="onSwipeLeft"  v-touch:swipe.right="onSwipeRight">
      <img src="../assets/img/class/action.png"  ondragstart="return false;" alt="">
      <div class="to" @click="onSwipeLeft">
          <img src="../assets/img/home/right.png" alt="">
      </div>
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
      level: '',
      img: '',
    };
  },
  created() {
  },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
    onSwipeLeft() {
      this.$router.push({
        name: 'Planning',
      });
    },
  },
};
</script>
<style lang="less">
.exhibit{
    width: 100vw;
    min-height: 100vh;
    position: relative;
    img{
        width: 100%;
    }
    .level{
        position: absolute;
        width: 8vw;

    }
    .l1{
        left: 13vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l2{
        left: 21.2vw;
        top: 24.8vw;
        height: 44vw;
    }
    .l3{
        left: 29.3vw;
        top: 23.2vw;
        height: 45.4vw;
    }
}
</style>
